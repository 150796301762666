/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import React from 'react';
import { Link } from 'react-router-dom';
import {CasePropTypes} from "../../core/containers/homepage/shapes";
import PropTypes from 'prop-types';
import HelpiButton from '../helpi-button';
import { HELPI_BUTTON_CLASSES, USER_STATES, IS_SUBDOMAIN } from '../../common/constants';
import GroupIcon from '../../assets/img/group.svg';
import './_style.scss';

class CaseCardSuccess extends React.PureComponent {

    generateVolunteerCompanyText = () => {
        const {volunteerGroupLeader, tasks, maximumVolunteers} = this.props;

        const spacesLeft = maximumVolunteers - tasks[0]['groupVolunteers'].length;

        const moreThanThreePlaces = spacesLeft > 3;

        const text = moreThanThreePlaces || maximumVolunteers === 0 ? `הצטרפו ל${volunteerGroupLeader.split(' ')[0]}!` : spacesLeft === 1 ? `נותר מקום אחרון!` : spacesLeft === 0 ? 'ההתנדבות מלאה !' :`נותרו עוד ${spacesLeft} מקומות בלבד!`;

        return (
            <div className="text-wrapper success">
                {text}
            </div>
        )

    };

    onDisabledCaseClick = () => this.props.onDisabledCaseClick();

    render() {
        const generateCaseImage = () => <img src={this.props.imgUrl} alt="" className="case-img success"/>;

        const isWLMulti = this.props.whiteLabel && this.props.isMultipleVolunteers && this.props.tasks[0].groupVolunteers.length > 0;

        const volunteerCompanyText = isWLMulti ? this.generateVolunteerCompanyText() : null;

        let buttonClassList = [HELPI_BUTTON_CLASSES.SECONDARY, HELPI_BUTTON_CLASSES.REGISTER];
        if (IS_SUBDOMAIN) buttonClassList.push(HELPI_BUTTON_CLASSES.ORANGE)

        const link = this.props.whiteLabel ? `/c/${this.props.serialNumber}/gr` : `/c/${this.props.serialNumber}`;

        const displayDisabled = !IS_SUBDOMAIN && this.props.allowedUserState === USER_STATES.USER_AUTHORIZED && this.props.showAuthCaseBanner;

        return  (
            <div className={`case-card case-container ${displayDisabled ? 'authorized' : ''} success`} onClick={displayDisabled ? this.onDisabledCaseClick : null}>
                <div className="case card-container success">
                    {
                        displayDisabled &&
                        <div className="authorized-case success">
                            <span>ההתנדבות פתוחה לחברות שותפות בלבד</span>
                            <span className="disabled-link success">מה זה אומר?</span>
                        </div>
                    }
                    <div className='wrap-img success'>
                        { (this.props.withButton) ? <Link to={link}>
                            {generateCaseImage()}
                            </Link>
                            :
                            generateCaseImage()
                        }
                        {isWLMulti &&
                        <div className="company-case-frame success">
                            <div className="group-icon-wrapper success"><img src={GroupIcon} alt=""/></div>
                            {volunteerCompanyText}
                        </div>
                        }
                    </div>
                    <div className="case-details success">
                        <div className="case-title success">{this.props.title}</div>
                        {this.props.withButton && <div className="case-actions success">
                            <Link to={link}>
                                <HelpiButton  classList={buttonClassList} label="ספרו לי עוד" />
                            </Link>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

CaseCardSuccess.propTypes = {
    ...CasePropTypes,
    onClick: PropTypes.func,
    withButton: PropTypes.bool
};

CaseCardSuccess.defaultProps = {
    withButton: true
};

export default CaseCardSuccess;
