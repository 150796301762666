import React from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import AuthConsumer from './../../../core/containers/auth/auth-protected';
import LoginProfileLink from '../login-profile';
import {IS_SUBDOMAIN} from "../../../common/constants";
import ItemMenu from '../item-menu';


class HeaderMenu extends React.Component {


    constructor(props) {
        super(props);
    

    }


   


    /**
     * open authentication modal
     */
    onLoginClick = () => {

    };

    render() {

       const  newItems =  this.props.items.filter((link) => !link.mobile && (!IS_SUBDOMAIN || !link.notInWhitelabel) && (!link.hide || link.hide.apply(null, [this.props.user, this.props.whiteLabel])))
       
        return (
            <div className={`header-content ${IS_SUBDOMAIN ? 'whitelabel' : ''}`}>
                <ul className="nav-bar">
                    {
                       
                        newItems.map((item, index) => {
                            return (
                                <li key={`${item.link}-${index}`} className={`nav-bar-section left-border-gray  ${item.classes ? item.classes : ''}`}>
                                  
                                  {<>
                              
                                    <ItemMenu item={item} key ={index} location={ this.props.location}/>
                                  </>}
                                </li>
                            )
                        })

                    }

                </ul>
               < div className='buttons'>
            <ItemMenu item={this.props.askHelpButton} className="ask-help" user={this.props.user}
            />
                <LoginProfileLink
                    className="nav-bar"
                    path={this.props.path}
                    user={this.props.user}
                    authenticated={this.props.authenticated}
                    logOut={this.props.logOut}
                />
                </div>
            </div>
        );
    }
}

HeaderMenu.propTypes = {
    items: PropTypes.array.isRequired,
    authenticated: PropTypes.bool
};

export default withRouter(AuthConsumer(HeaderMenu));
