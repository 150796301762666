import React, {PureComponent} from 'react';
import './style.scss';
import {Link} from 'react-router-dom';
import {HEADER_USER_LINKS, SIGN_UP_CONTEXTS} from '../../../common/constants';
import RoleProtected from '../../role-protected';
import { USER_ROLES } from '../../../common/constants';

class LoginProfileLink extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    open = () => this.setState({open: true});

    close = () => this.setState({open: false});

    toggle = () => this.setState({open: !this.state.open});

    generateList = () => {
        return HEADER_USER_LINKS
        .map((link, i) => {
            const changeRouteForResponsibleRole = this.props.user.role === USER_ROLES.RESPONSIBLE
                        && link.roles && link.roles.includes(USER_ROLES.RESPONSIBLE)
                          ? '/admin/#/admin/companies' : link.link;
                    const menuLink = (
                        <li key={`user-link-item-${i}`}>
                            {typeof link.link === "function"
                                ?
                                <div onClick={this.props.logOut}>{link.displayName}</div>
                                :
                                link.targetSelf
                                    ?
                                    <Link to={changeRouteForResponsibleRole} target={'_self'}>{link.displayName}</Link>
                                    :
                                <Link to={link.link}>{link.displayName}</Link>
                            }
                        </li>
                    );

                    if (link.roles) {
                        return (<RoleProtected key={`user-link-role-protected-${i}`} roles={[...link.roles]}>
                                {menuLink}
                            </RoleProtected>
                        )
                    }
                    return menuLink;
                }
            )

    };

    generateUserDropdown = () => {
        const {user} = this.props;
        const {open} = this.state;
        const list = this.generateList();
        const name = `שלום ${user.firstName}`;

        return (
            <div className="user-dropdown" onMouseEnter={this.open} onMouseLeave={this.close} onClick={this.toggle}>
                <div className="name">{name}</div>
                <ul className={`list-wrapper ${open ? 'open' : ''}`}>
                    {list}
                </ul>
            </div>
        )
    };

    render() {
        const {authenticated} = this.props;
        const link = {
            pathname: '/new-signin',
            state: {
                returnPath: this.props.path,
                context: SIGN_UP_CONTEXTS.NONE
            }
        }

        const button = !authenticated ? <Link className="login-button" to={link}>הרשמה/כניסה</Link> : this.generateUserDropdown();
        return (
            <div className="login-profile-wrapper">
                {button}
            </div>
        )
    }
}

export default LoginProfileLink;